
import { Options, Vue } from 'vue-class-component';
import { AppUserClient, InternalUserClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import { StorageServices } from '@/services/StorageServices';
import ErrorModal from '@/modals/errorModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Login extends Vue {

    model: OM.CultureBaseCredential = new OM.CultureBaseCredential();
    selectedLanguage: string = "";

    created() {
        this.selectedLanguage = StorageServices.getLoggedUser().preferredCulture;
    }

    login() {
        AppUserClient.login(this.model)
        .then(x => {
            console.log(x);
            LoginServices.LoginCallback(x);
        })
        .catch(y => {
            this.$opModal.show(ErrorModal, {
                title: this.$localizationService.getLocalizedValue('app_errore', 'Errore'),
                description: this.$localizationService.getLocalizedValue('app_UtenteNonEsistenteONonConfermato', 'Utente non esistente o non confermato'),
            })
        })
    }

    get disabled() {
        return !this.model.username || !this.model.password;
    }

}
