import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "p-4" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_localized_text, {
      type: "h1",
      localizedKey: "app_Login",
      text: "Login",
      class: "text-center"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_form_field, {
        type: "email",
        modelValue: _ctx.model.username,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.username) = $event)),
        placeholderKey: "app_Email*",
        placeholderValue: "Email*"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_form_field, {
        class: "pb-3",
        type: "password",
        modelValue: _ctx.model.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.password) = $event)),
        placeholderKey: "app_Password*",
        placeholderValue: "Password*"
      }, null, 8, ["modelValue"]),
      _createElementVNode("button", {
        class: "btn btn-primary w-100 mt-5 mb-4",
        disabled: _ctx.disabled,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.login()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_Login",
          text: "Login"
        })
      ], 8, _hoisted_3)
    ])
  ]))
}